import { api } from ":mods";
import { ENDPOINTS } from "../const";

export async function getRegions(country_id: string) {

  return api
    .getAuth(ENDPOINTS.GET_REGIONS.replace("{country}",country_id))
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
